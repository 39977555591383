<template>
  <div class="orderScheduling">
    <div class="box-card father-card"
         shadow="never">
      <!-- 按钮区域 -->
      <el-row>
        <el-form ref="form"
                 :model="form"
                 inline>
          <el-form-item label="订单开始结束时间">
            <WTimer ref="WTimer"
                    v-model="form"
                    :startEnd="{start:'startOrderDate',end:'endOrderDate'}" />
          </el-form-item>
          <el-form-item>
            <el-button type="success"
                       icon="el-icon-search"
                       @click="search()">搜索</el-button>
            <el-button type="primary"
                       icon="el-icon-delete"
                       @click="reset()">重置</el-button>
            <el-button type="primary"
                       @click="addDialogFormVisible = true"
                       icon="el-icon-circle-plus">
              添加订单
            </el-button>
          </el-form-item>

        </el-form>

        <!--  <el-button type="primary" @click="edit()">编辑订单</el-button>-->
        <!-- <el-button type="primary">导出客户</el-button> -->
      </el-row>
      <!-- 表格区域 -->
      <el-table ref="multipleTable"
                :data="clientList"
                tooltip-effect="dark"
                style="width: 100%"
                highlight-current-row
                stripe
                border
                :header-cell-style="rowClass">
        <el-table-column prop="orderNo"
                         label="编号"
                         align="center"></el-table-column>
        <el-table-column prop="memberName"
                         label="用户姓名"
                         align="center"></el-table-column>
        <el-table-column prop="memberMobile"
                         label="联系电话"
                         align="center"></el-table-column>
        <el-table-column prop="startAddress"
                         label="出发地"
                         align="center"></el-table-column>
        <el-table-column prop="endAddress"
                         label="目的地"
                         align="center"></el-table-column>
        <el-table-column prop="transferVehicle"
                         label="转运类型"
                         align="center"></el-table-column>
        <el-table-column prop="createTime"
                         label="下单时间"
                         align="center"></el-table-column>
        <el-table-column prop="orderType"
                         label="订单类型"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.orderType == 0 ? '立即用车' : '预约用车' }}
          </template>
        </el-table-column>
        <el-table-column prop="orderReserveDate"
                         label="预约时间"
                         align="center">
          <template slot-scope="scope">
            {{
                            scope.row.orderType == 0
                                ? '无'
                                : scope.row.orderReserveDate
                        }}
          </template>
        </el-table-column>
        <el-table-column prop="applicationCode"
                         label="订单来源"
                         align="center">
          <template slot-scope="scope">
            <span effect="dark"
                  type="success"
                  v-if="scope.row.applicationCode === '10001'">{{ '小程序' }}
            </span>
            <span effect="dark"
                  type="success"
                  v-else-if="scope.row.applicationCode === '10000'">{{ '平台' }}
            </span>
            <span effect="dark"
                  type="success"
                  v-else-if="scope.row.applicationCode === '10002'">{{ 'APP' }}
            </span>
            <span effect="dark"
                  type="success"
                  v-else-if="scope.row.applicationCode === '10003'">{{ '健康卡' }}
            </span>

            <span effect="dark"
                  type="success"
                  v-else-if="scope.row.applicationCode === '10008'">{{ '公众号H5' }}
            </span>
            <span effect="dark"
                  type="success"
                  v-else>{{
                            '优医指南'
                        }}</span>
          </template>
        </el-table-column>
        <el-table-column label="详情"
                         align="center"
                         min-width="150px">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="look(scope.row)"
                       type="success">查看详情</el-button>
            <el-button size="mini"
                       type="primary"
                       @click="allot(scope.row)"
                       icon="el-icon-thumb">分配
            </el-button>
            <el-popconfirm confirm-button-text="确定"
                           cancel-button-text="不用了"
                           icon="el-icon-info"
                           icon-color="red"
                           title="确定要取消该订单吗？"
                           @confirm="refund(scope.row)">
              <el-button slot="reference"
                         type="danger"
                         icon="el-icon-delete"
                         size="mini"
                         style="margin-left: 5px">取消</el-button>
            </el-popconfirm>
            <!-- <el-popconfirm  confirm-button-text='确定' cancel-button-text='不用了'  icon="el-icon-info"  icon-color="red"
			  title="确定要提前完成该订单吗？"  @confirm="ahead(scope.row)">
			  <el-button slot="reference">提前完成</el-button>
			</el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handlePageNumChange"
                     :current-page="paging.pageNo"
                     :page-sizes="[5, 10, 20, 50]"
                     :page-size="paging.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total"></el-pagination>
      <!-- 编辑弹出框 -->
      <el-dialog :visible.sync="editDialogFormVisible"
                 class="edit-dialog"
                 @close="editDialogClose()">
        <span slot="title"
              class="dialog-Title">编辑线上预约信息</span>
        <el-form :model="editForm"
                 inline
                 ref="editForm"
                 :rules="editFormRules">
          <el-row>
            <el-form-item label="用户姓名"
                          prop="userName">
              <el-input v-model="editForm.userName"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="联系电话"
                          prop="iphone">
              <el-input v-model="editForm.iphone"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="转运类型"
                          prop="transportTypeId">
              <el-select v-model="editForm.transportTypeId"
                         placeholder="请选择转运类型">
                <el-option :label="item"
                           :value="item"
                           v-for="(item, index) in TransportType"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row class="el-row-two">
            <el-form-item label="出发地"
                          prop="origin">
              <el-input readonly v-model="editForm.origin">
                <i slot="suffix"
                   class="location"
                   @click="location()"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="目的地"
                          prop="destination">
              <el-input readonly v-model="editForm.destination"
                        autocomplete="off">
                <i slot="suffix"
                   class="location"
                   @click="location()"></i>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="预约时间"
                          prop="appointmentTime">
              <el-date-picker type="date"
                              placeholder="选择日期"
                              v-model="editForm.appointmentTime">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="搬抬楼层"
                          prop="removeFloor">
              <el-input v-model="editForm.removeFloor"
                        autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="搬抬人数"
                          prop="removeNumber">
              <el-input v-model="editForm.removeNumber"
                        autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
          <el-button type="info"
                     @click="editDialogFormVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="editClient()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 分配弹出框 -->
      <el-dialog :visible.sync="allocationDialogFormVisible"
                 class="allocation-dialog"
                 @close="allootDialogClose()">
        <span slot="title"
              class="dialog-Title">分配订单</span>
        <el-form :model="showallot"
                 inline
                 ref="showallot"
                 :rules="allotFormRules">
          <div class="fpddcolor">
            <div class="fpddcolor-title">订单基本信息</div>
            <!-- <div class="daijiaocolor">
                    <div class="daijiaocolor-left"><img src="../../images/us.png">客户信息</div>
                </div> -->
            <div class="fpddcolor-top"
                 style="
                                background-color: #fff;
                                border-bottom: #f3f6fa 2px solid;
                            ">
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  下单人姓名
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.memberName }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  手机号码
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.memberMobile }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  订单来源
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  <!-- {{showallot.applicationCode == 10000 ? '平台' : showallot.applicationCode == 10001 ? '小程序':'APP'}} -->
                  <span v-if="
                                            showallot.applicationCode == 10000
                                        ">平台</span>
                  <span v-else-if="
                                            showallot.applicationCode == 10001
                                        ">小程序</span>
                  <span v-else-if="
                                            showallot.applicationCode == 10002
                                        ">APP</span>
                  <span v-else-if="
                                            showallot.applicationCode == 10003
                                        ">健康卡</span>
                  <span v-else-if="
                                            showallot.applicationCode == 10008
                                        ">公众号H5</span>
                  <span v-else>优医指南</span>
                </div>
              </div>
            </div>

            <!-- <div class="daijiaocolor">
                    <div class="daijiaocolor-left"><img src="../../images/us.png">订单信息</div>
                </div> -->
            <div class="fpddcolor-in"
                 style="
                                background-color: #fff;
                                display: flex;
                                border-bottom: #f3f6fa 2px solid;
                            ">
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  下单时间
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.createTime }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  订单编号
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.orderNo }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  转运类型
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.transferVehicle }}
                </div>
              </div>
            </div>
            <div class="fpddcolor-in"
                 style="
                                background-color: #fff;
                                display: flex;
                                border-bottom: #f3f6fa 2px solid;
                            ">
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  预估金额
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.orderPredictFee }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  预估预付
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.orderPredictPay }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  预估公里
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ showallot.predictMile }}
                </div>
              </div>
            </div>
            <!-- <div class="daijiaocolor">
                    <div class="daijiaocolor-left"><img src="../../fonts/location@2x.png">位置信息</div>
                </div> -->
            <div class="fpddcolor-low"
                 style="background-color: #fff">
              <div class="cflex"
                   style="border-bottom: #f3f6fa 2px solid">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  起始地址
                </div>
                <div style="padding: 10px 20px">
                  {{ showallot.startOrderAddress }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  目的地址
                </div>
                <div style="padding: 10px 20px">
                  {{ showallot.orderHospName }}
                </div>
              </div>
            </div>
          </div>
        </el-form>

        <!-- showallot -->
        <el-form :model="allotForm"
                 inline
                 ref="allotForm"
                 :rules="allotFormRules">
          <div class="fpaddcolor">
            <div class="daijiaocolor">
              <div class="daijiaocolor-left"
                   style="padding-left: 10px">
                <img src="../../images/sz.png" />分配订单(必选)
              </div>
            </div>
            <div class="fpaddcolor-tip">
              <!-- <el-form-item label="选择区域" prop="area">
                                <el-cascader
                                    placeholder="请选择区域"
                                    :options="options"
                                    filterable
                                    :props="props"
                                    @change="ad"
                                    v-model="allotForm.area"
                                    :disabled="true"
                                ></el-cascader>
                            </el-form-item> -->
              <el-form-item label="机构名称"
                            prop="orderCompany">
                <el-select v-model="allotForm.orderCompany"
                           placeholder="请选择机构"
                           @change="dd(allotForm)"
                           clearable>
                  <el-option :label="item.companyName"
                             :value="item.corpId"
                             v-for="(item, index) in orderCompany"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="转运类型"
                            prop="transportTypeId">
                <el-select v-model="allotForm.transportTypeId"
                           placeholder="请选择转运类型"
                           @change="
                                        TransportTypeChange(
                                            allotForm.transportTypeId
                                        )
                                    ">
                  <el-option :label="item.transportTypeName"
                             :value="item.uuid"
                             v-for="(item, index) in TransportType"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="是否回程单"
                            prop="isReturnOrder">
                <el-select v-model="allotForm.isReturnOrder"
                           filterable
                           clearable
                           style="width: 150px">
                  <el-option label="是"
                             value="1"></el-option>
                  <el-option label="否"
                             value="0"></el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="fpaddcolor-in">
              <!-- <el-row> -->
              <el-popover placement="bottom-start"
                          v-model="isVerification.carNum.is"
                          trigger="click"
                          class="dy-popover">
                <div class="dy-content">
                  <div class="content-head"
                       v-if="stautsjc">
                    <div class="head-input">
                      <span>{{
                                                    isVerification.carNum.title
                                                }}:&nbsp;&nbsp;</span>
                      <el-input style="width: 100%"
                                v-model="
                                                    isVerification.carNum.input
                                                        .carNum
                                                "
                                placeholder="请输入车牌号码"></el-input>
                    </div>
                    <el-button type="primary"
                               size="mini"
                               @click="
                                                searchCarNum(
                                                    isVerification.carNum
                                                )
                                            ">搜索</el-button>
                    <el-button type="info"
                               size="mini"
                               @click="clrCarNum()">重置</el-button>
                  </div>
                  <div class="content-table"
                       v-if="stautsjc">
                    <el-table :data="
                                                isVerification.carNum.tableData
                                            "
                              height="250"
                              border
                              style="width: 100%">
                      <el-table-column prop="companyName"
                                       label="所属机构"
                                       width="150">
                      </el-table-column>
                      <el-table-column prop="carNum"
                                       label="车牌号码"
                                       width="100"></el-table-column>
                      <el-table-column label="操作"
                                       width="70">
                        <template slot-scope="scope">
                          <el-button type="primary"
                                     size="small"
                                     @click="
                                                            selCarNum(scope.row)
                                                        ">确认</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div class="dy-sel"
                     slot="reference"
                     @click="searchCarNum(isVerification.carNum)">
                  <div class="sel-title">
                    <span class="title-icon"
                          v-if="
                                                isVerification.carNum
                                                    .isMandatory
                                            ">*</span><span class="title-text">{{
                                            isVerification.carNum.title
                                        }}</span>
                  </div>
                  <div class="sel-input"
                       :class="isVerification.carNum.text? '': 'dycolor'">
                    {{isVerification.carNum.text? isVerification.carNum.text: '请选择车牌号码'}}
                  </div>
                </div>
              </el-popover>
              <el-popover placement="bottom-start"
                          v-model="isVerification.driverName.is"
                          trigger="click"
                          class="dy-popover">
                <div class="dy-content">
                  <div class="content-head"
                       v-if="stautsjc">
                    <div class="head-input">
                      <span>{{
                                                    isVerification.driverName
                                                        .title
                                                }}:&nbsp;&nbsp;</span>
                      <el-input style="width: 100%"
                                v-model="
                                                    isVerification.driverName
                                                        .input.driverName
                                                "
                                placeholder="请输入车牌号码"></el-input>
                    </div>
                    <el-button type="primary"
                               size="mini"
                               @click="
                                                searchDriverName(
                                                    isVerification.driverName
                                                )
                                            ">搜索</el-button>
                    <el-button type="info"
                               size="mini"
                               @click="clrDriverName()">重置</el-button>
                  </div>
                  <div class="content-table"
                       v-if="stautsjc">
                    <el-table :data="
                                                isVerification.driverName
                                                    .tableData
                                            "
                              height="250"
                              border
                              style="width: 100%">
                      <el-table-column prop="companyName"
                                       label="所属机构"
                                       width="150">
                      </el-table-column>
                      <el-table-column prop="driverName"
                                       label="司机名称"
                                       width="100">
                      </el-table-column>
                      <el-table-column label="操作"
                                       width="70">
                        <template slot-scope="scope">
                          <el-button type="primary"
                                     size="small"
                                     @click="
                                                            selDriverName(
                                                                scope.row
                                                            )
                                                        ">确认</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div class="dy-sel"
                     slot="reference"
                     @click="
                                        searchDriverName(
                                            isVerification.driverName
                                        )
                                    ">
                  <div class="sel-title">
                    <span class="title-icon"
                          v-if="
                                                isVerification.driverName
                                                    .isMandatory
                                            ">*</span><span class="title-text">{{
                                            isVerification.driverName.title
                                        }}</span>
                  </div>
                  <div class="sel-input"
                       :class="
                                            isVerification.driverName.text
                                                ? ''
                                                : 'dycolor'
                                        ">
                    {{
                                            isVerification.driverName.text
                                                ? isVerification.driverName.text
                                                : '请选择车牌号码'
                                        }}
                  </div>
                </div>
              </el-popover>
              <el-form-item label="是否收取接送费"
                            prop="isPick">
                <el-select v-model="allotForm.isPick"
                           filterable
                           clearable
                           style="width: 150px">
                  <el-option label="收取"
                             value="1"></el-option>
                  <el-option label="不收取"
                             value="0"></el-option>
                </el-select>
              </el-form-item>

              <!-- </el-row> -->
            </div>
            <div class="fpaddcolor-in">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="出发地">
                    <el-input class="sel-input"
                              placeholder="请输入出发地医院"
                              autocomplete="off"
                              clearable
                              v-model="allotForm.startHospName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="科室">
                    <el-input placeholder="请输科室"
                              autocomplete="off"
                              clearable
                              v-model="allotForm.startDepartment"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="床位">
                    <el-input placeholder="请输入床位"
                              autocomplete="off"
                              clearable
                              v-model="allotForm.startBed"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="fpaddcolor-in">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="业务员">
                    <el-input placeholder="请输入业务员"
                              autocomplete="off"
                              clearable
                              v-model="allotForm.salesman"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <!-- <el-row> -->
            <div class="fpaddcolor-low">
              <div class="daijiaocolor">
                <div class="daijiaocolor-left"
                     style="
                                        padding-left: 10px;
                                        width: 100%;
                                        text-align: center;
                                    ">
                  <img src="../../images/us.png" />跟车医护人员
                </div>
              </div>
              <el-form-item label="护送人员:">
                <el-select v-model="allotForm.escorts"
                           placeholder="请选择护送人员"
                           filterable
                           clearable
                           multiple
                           @change="selectEscort(1)">
                  <el-option :label="item.DOC_NAME"
                             :value="item.uuid"
                             v-for="(item, index) in escorts"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="随车医生:">
                <el-select v-model="allotForm.doctorPersonId"
                           placeholder="请选择随车医生"
                           filterable
                           clearable
                           multiple
                           @change="selectDoc(1)">
                  <el-option :label="item.DOC_NAME"
                             :value="item.uuid"
                             v-for="(item, index) in doctor"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
              <div>
                <!-- <el-popover
                                    placement="bottom-start"
                                    v-model="isVerification.nursePerson.is"
                                    trigger="click"
                                    class="dy-popover"
                                    @show="popoverShowClick"
                                    :disabled="false"
                                >
                                    <div class="dy-content">
                                        <div
                                            class="content-head"
                                            v-if="stautsjc"
                                        >
                                            <div class="head-input">
                                                <span
                                                    >{{
                                                        isVerification
                                                            .nursePerson.title
                                                    }}:&nbsp;&nbsp;</span
                                                >
                                                <el-input
                                                    style="width: 100%"
                                                    v-model="
                                                        isVerification
                                                            .nursePerson.input
                                                            .nursePersonId
                                                    "
                                                    placeholder="请选择随车护士"
                                                ></el-input>
                                            </div>
                                            <el-button
                                                type="primary"
                                                size="mini"
                                                @click="
                                                    searchNurse(
                                                        isVerification.nursePerson
                                                    )
                                                "
                                                >搜索</el-button
                                            >
                                            <el-button
                                                type="info"
                                                size="mini"
                                                @click="clrNurse()"
                                                >重置</el-button
                                            >
                                        </div>
                                        <div
                                            class="content-table"
                                            v-if="stautsjc"
                                        >
                                            <el-table
                                                :data="nurse"
                                                height="250"
                                                border
                                                style="width: 100%"
                                            >
                                                <el-table-column
                                                    prop="companyName"
                                                    label="所属机构"
                                                    width="150"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                    prop="DOC_NAME"
                                                    label="随车护士"
                                                    width="100"
                                                >
                                                </el-table-column>
                                                <el-table-column
                                                    label="操作"
                                                    width="70"
                                                >
                                                    <template
                                                        slot-scope="scope"
                                                    >
                                                        <el-button
                                                            type="primary"
                                                            size="small"
                                                            @click="
                                                                selCarNursePerson(
                                                                    scope.row
                                                                )
                                                            "
                                                            >确认</el-button
                                                        >
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                    <div
                                        class="dy-sel"
                                        slot="reference"
                                        @click="
                                            searchNurse(
                                                isVerification.nursePerson
                                            )
                                        "
                                    >
                                        <div class="sel-title">
                                            <span
                                                class="title-icon"
                                                v-if="
                                                    isVerification.nursePerson
                                                        .isMandatory
                                                "
                                            ></span
                                            ><span class="title-text"
                                                >{{
                                                    isVerification.nursePerson
                                                        .title
                                                }}:</span
                                            >
                                        </div>
                                        <div
                                            class="sel-input"
                                            :class="
                                                isVerification.nursePerson.text
                                                    ? ''
                                                    : 'dycolor'
                                            "
                                        >
                                            {{
                                                isVerification.nursePerson.text
                                                    ? isVerification.nursePerson
                                                          .text
                                                    : '请选择随车护士'
                                            }}
                                        </div>
                                    </div>
                                </el-popover> -->
                <el-form-item label="随车护士:">
                  <el-select v-model="allotForm.nursePersonId"
                             placeholder="请选择随车护士"
                             filterable
                             clearable
                             multiple
                             @change="selectNurse(1)">
                    <el-option :label="item.DOC_NAME"
                               :value="item.uuid"
                               v-for="(item, index) in nurse"
                               :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="搬抬人员:">
                  <el-select v-model="allotForm.carryPersonId"
                             placeholder="请选择搬抬人员"
                             filterable
                             clearable
                             multiple>
                    <el-option :label="item.DOC_NAME"
                               :value="item.uuid"
                               v-for="(item, index) in lifting"
                               :key="index"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <!-- </el-row> -->
          </div>
          <el-row>
            <div class="daijiaocolor"
                 v-if="showallot.applicationCode != 10004">
              <div class="daijiaocolor-left"
                   style="padding-left: 10px">
                <img src="../../images/many.png" />议价费用(选填)<el-checkbox v-model="bargaining"
                             @change="bargainingChange(showallot)"
                             style="zoom: 130%; margin-left: 10px">
                </el-checkbox>
              </div>
              <div v-show="show5"
                   class="daijiaocolor-right">
                <el-form-item label="议价费用:">
                  <el-input v-model="allotForm.bargainMoney"
                            placeholder="请输入议价费用"
                            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
                            @input="changebargainMoney">
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </el-row>
        </el-form>
        <div style="text-align: right; color: red; font-size: 14px">
          温馨提示：在选择完信息后请检查一遍，已免造成错误分配
        </div>
        <div slot="footer"
             class="dialog-footer">
          <el-button type="info"
                     @click="allocationDialogFormVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="allotClient()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible"
                 class="add-dialog"
                 @close="addDialogClose()"
                 :close-on-click-modal="false">
        <span slot="title"
              class="dialog-Title">添加订单</span>
        <el-form :model="addForm"
                 inline
                 ref="addForm"
                 :rules="addFormRules">
          <!-- 第一行 -->
          <div class="addresscolor">
            <div class="addresscolor-left">
              <span style="width: 70px">出发地:</span>
              <el-input v-model="addForm.startAddress"
                        autocomplete="off"
                        clearable readonly
                        placeholder="请选择出发地"
                        @focus="selectAddress('0')">
                <i slot="suffix"
                   class="el-input__icon el-icon-location-outline dw"
                   @click="selectAddress('0')"></i>
              </el-input>
            </div>
            <div class="addresscolor-right">
              <span style="width: 70px">目的地:</span>
              <el-input v-model="addForm.endAddress"
                        autocomplete="off"
                        clearable readonly
                        placeholder="请选择目的地"
                        @focus="selectAddress('1')">
                <i slot="suffix"
                   class="el-input__icon el-icon-location-outline dw"
                   @click="selectAddress('1')"></i>
              </el-input>
            </div>
          </div>
          <!-- 第二行 -->
          <div class="fipstype">
            <img src="../../images/us.png" />转运类型
          </div>
          <!-- 第三行 -->
          <div class="transportTypeIdBox">
            <div v-for="(item, index) in TransportType"
                 :key="index"
                 :class="active === item.uuid ? 'active' : 'index'"
                 @click="checkTransType(item.uuid)">
              {{ item.transportTypeName }}
            </div>
          </div>
          <div class="liftingcolor">
            <div>
              <img src="../../images/ban.png" />搬抬服务<el-checkbox v-model="addForm.isStretcher"
                           @change="isStretcherChange"
                           style="zoom: 130%; margin-left: 10px"></el-checkbox>
            </div>
            <div v-if="show1"
                 class="liftingcolor-right">
              <el-form-item label="搬抬楼层数">
                <el-input-number v-model="addForm.stretcherNum"
                                 :min="1"
                                 :max="10"
                                 label="搬抬楼层数:"
                                 @change="countMoney"
                                 size="mini"></el-input-number>
              </el-form-item>
              <el-form-item label="搬抬人数">
                <el-input-number v-model="addForm.stretcherPersonNum"
                                 :min="1"
                                 :max="10"
                                 label="搬抬人数:"
                                 @change="countMoney"
                                 size="mini"></el-input-number>
              </el-form-item>
            </div>
          </div>
          <!-- 第四行 -->
          <div class="daijiaocolor">
            <div class="daijiaocolor-left">
              <img src="../../images/dai.png" />代叫<el-checkbox v-model="isReplace"
                           @change="isReplaceChange()"
                           style="zoom: 130%; margin-left: 10px">
              </el-checkbox>
            </div>
            <div v-show="show2"
                 class="daijiaocolor-right">
              <el-form-item label="代叫人姓名:">
                <el-input v-model="addForm.orderContactName"
                          autocomplete="off"
                          clearable
                          placeholder="请输入代叫人姓名"></el-input>
              </el-form-item>
              <el-form-item label="代叫人电话:">
                <el-input v-model="addForm.orderContactMobile"
                          autocomplete="off"
                          clearable
                          placeholder="请输入代叫人电话号码"></el-input>
              </el-form-item>
            </div>
          </div>
          <!-- 第五行 -->
          <div class="addcolor">
            <div class="addflex addbutooncolor">
              <div class="flex-left">
                <img src="../../images/many.png" />预估费用
              </div>
              <span class="flex-right"
                    style="color: #ff7f50">{{ moneyForm.allMoney }}元</span>
            </div>
            <div class="addflex addsizecolor">
              <div class="flex-left">
                里程费(<span style="color: #ff7f50">{{ moneyForm.predictMile }}公里</span>)
              </div>
              <span class="flex-right">{{ moneyForm.milePay }}￥</span>
            </div>
            <div class="addflex addsizecolor">
              <div class="flex-left">起步费({{moneyForm.startMile}}公里)</div>
              <span class="flex-right">{{ moneyForm.transPayBase }}￥</span>
            </div>
            <div class="addflex addsizecolor">
              <div class="flex-left">转运费</div>
              <span class="flex-right">{{ moneyForm.transPay }}￥</span>
            </div>
            <div class="addflex addsizecolor">
              <div class="flex-left">
                监护搬抬服务费(1人、1层)
              </div>
              <div class="flex-right">
                {{ moneyForm.btPay }}￥
              </div>
            </div>
          </div>
          <div class="patientcolor">
            <div class="patientcolor-left">
              病人资料(选填)<el-checkbox v-model="PatientData"
                           @change="PatientDataChange()"
                           style="zoom: 130%; margin-left: 10px"></el-checkbox>
            </div>
          </div>
          <el-collapse-transition>
            <div v-show="show3"
                 class="patientcolor-right">
              <el-form-item label="病人姓名">
                <el-input v-model="addForm.patientName"
                          placeholder="请输入病人姓名"
                          autocomplete="off"
                          clearable></el-input>
              </el-form-item>
              <el-form-item label="病人年龄">
                <el-input v-model="addForm.patientAge"
                          placeholder="请输入病人年龄"
                          autocomplete="off"
                          clearable></el-input>
              </el-form-item>
              <el-form-item label="病人电话">
                <el-input v-model="addForm.patientMobile"
                          placeholder="请输入病人电话"
                          autocomplete="off"
                          clearable></el-input>
              </el-form-item>
              <el-row>
                <el-input v-model="addForm.patientConditionRemark"
                          placeholder="请输入病情描述"
                          autocomplete="off"
                          clearable
                          style="width: 100%; text-align: center"></el-input>
              </el-row>
            </div>
          </el-collapse-transition>

          <div class="nexpersoncolor">
            <div class="nexpersoncolor-left">
              <img src="../../fonts/smaller.png" />下单人信息
            </div>
            <div>
              <el-form-item label="下单用户姓名:">
                <el-input v-model="addForm.memberName"
                          autocomplete="off"
                          placeholder="请输入下单人姓名"
                          clearable></el-input>
              </el-form-item>
              <el-form-item label="下单人电话:">
                <el-input v-model="addForm.memberMobile"
                          autocomplete="off"
                          placeholder="请输入下单人电话"
                          clearable
                          oninput="value=value.replace(/^\.+|[^\d.]/g,'')">

                  ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="ordertypecolor">
            <div class="ordertypecolor-left">
              <img src="../../images/sz.png" />订单类型
            </div>
            <div>
              <el-select v-model="addForm.orderType"
                         filterable
                         :value="addForm.orderType">
                <el-option label="立即用车"
                           value="0"></el-option>
                <el-option label="预约用车"
                           value="1"></el-option>
              </el-select>
            </div>
          </div>
          <el-row v-if="addForm.orderType == 1"
                  class="yuyuetime">
            <el-form-item label="预约时间">
              <el-col :span="11">
                <el-date-picker type="date"
                                placeholder="选择日期"
                                v-model="addForm.date1"
                                style="width: 100%"
                                :picker-options="pickerOptions0"></el-date-picker>
              </el-col>
              <el-col class="line"
                      :span="2">-</el-col>
              <el-col :span="11">
                <el-time-picker placeholder="选择时间"
                                v-model="addForm.date2"
                                style="width: 100%">
                </el-time-picker>
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
          <el-button type="info"
                     @click="addDialogFormVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="addClient()">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible"
                 class="order-dialog">
        <span slot="title"
              class="dialog-Title">调度详情</span>
        <el-form :model="orderForm"
                 inline
                 ref="orderForm">
          <div class="fpddcolor">
            <div class="fpddcolor-title">订单详情信息</div>
            <div class="daijiaocolor">
              <div class="daijiaocolor-left"
                   style="padding-left: 10px">
                <img src="../../images/us.png" />客户信息
              </div>
            </div>
            <div style="background-color: #fff; display: flex">
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  下单人姓名
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.memberName }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px 20px;
                                        width: 100px;
                                    ">
                  手机号码
                </div>
                <div style="padding: 10px 20px">
                  {{ orderForm.memberMobile }}
                </div>
              </div>
            </div>
            <div>
              <div class="daijiaocolor">
                <div class="daijiaocolor-left"
                     style="padding-left: 10px">
                  <img src="../../images/us.png" />患者信息
                </div>
              </div>
              <div style="
                                    display: flex;
                                    background-color: #fff;
                                    border-bottom: #f3f6fa 2px solid;
                                ">
                <div class="cflex">
                  <div style="
                                            background-color: #f3f6fa;
                                            padding: 10px;
                                            width: 100px;
                                            text-align: center;
                                        ">
                    患者姓名
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{
                                            orderForm.patientName || '暂无信息'
                                        }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                                            background-color: #f3f6fa;
                                            padding: 10px;
                                            width: 100px;
                                            text-align: center;
                                        ">
                    患者年龄
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{ orderForm.patientAge || '暂无信息' }}
                  </div>
                </div>
                <div class="cflex">
                  <div style="
                                            background-color: #f3f6fa;
                                            padding: 10px;
                                            width: 100px;
                                            text-align: center;
                                        ">
                    患者手机号码
                  </div>
                  <div style="padding: 10px 20px; width: 200px">
                    {{
                                            orderForm.patientMobile ||
                                            '暂无信息'
                                        }}
                  </div>
                </div>
              </div>
              <div style="
                                    display: flex;
                                    background-color: #fff;
                                    border-bottom: #f3f6fa 2px solid;
                                ">
                <div class="cflex">
                  <div style="
                                            background-color: #f3f6fa;
                                            padding: 10px;
                                            width: 100px;
                                            text-align: center;
                                        ">
                    病情
                  </div>
                  <div style="padding: 10px 20px">
                    {{
                                            orderForm.patientCondition ||
                                            '暂无信息'
                                        }}
                  </div>
                </div>
              </div>
              <div style="display: flex; background-color: #fff">
                <div class="cflex">
                  <div style="
                                            background-color: #f3f6fa;
                                            padding: 10px;
                                            width: 100px;
                                            text-align: center;
                                        ">
                    病情描述
                  </div>
                  <div style="padding: 10px 20px">
                    {{
                                            orderForm.patientConditionRemark ||
                                            '暂无信息'
                                        }}
                  </div>
                </div>
              </div>
            </div>
            <div class="daijiaocolor">
              <div class="daijiaocolor-left"
                   style="padding-left: 10px">
                <img src="../../fonts/home.png" />订单信息
              </div>
            </div>
            <div style="
                                background-color: #fff;
                                display: flex;
                                border-bottom: #f3f6fa 2px solid;
                            ">
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  订单编号
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.orderNo }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  转运类型
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.transferVehicle }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  订单来源
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  <!-- {{orderForm.applicationCode == 10000 ? '平台' : orderForm.applicationCode == 10001 ? '小程序':'APP'}} -->
                  <span v-if="
                                            orderForm.applicationCode == 10000
                                        ">平台</span>
                  <span v-else-if="
                                            orderForm.applicationCode == 10001
                                        ">小程序</span>
                  <span v-else-if="
                                            orderForm.applicationCode == 10002
                                        ">APP</span>
                  <span v-else-if="
                                            orderForm.applicationCode == 10003
                                        ">健康卡</span>
                  <span v-else-if="
                                            orderForm.applicationCode == 10008
                                        ">公众号H5</span>
                  <span v-else>优医指南</span>
                </div>
              </div>
            </div>
            <div class="fpddcolor-in"
                 style="
                                display: flex;
                                background-color: #fff;
                                border-bottom: #f3f6fa 2px solid;
                            ">
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  下单时间
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.createTime }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  预估金额
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.orderPredictFee }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  预估预付
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.orderPredictPay }}
                </div>
              </div>
            </div>
            <div class="fpddcolor-in"
                 style="display: flex; background-color: #fff">
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  预估公里数
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.predictMile }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  搬抬费用
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.carryMoney }}
                </div>
              </div>
              <div class="cflex">
                <div style="
                                        background-color: #f3f6fa;
                                        padding: 10px;
                                        width: 100px;
                                        text-align: center;
                                    ">
                  实际预付
                </div>
                <div style="padding: 10px 20px; width: 200px">
                  {{ orderForm.orderPredictRealityPay }}
                </div>
              </div>
            </div>
            <div class="daijiaocolor">
              <div class="daijiaocolor-left"
                   style="padding-left: 10px">
                <img src="../../fonts/location@2x.png" />位置信息
              </div>
            </div>
            <div class="fpddcolor-low"
                 style="background-color: #fff;padding: 10px;line-height: 30px;">
              <div style="color: #0097fe;display: flex;align-items: center;margin-top: 10px;">
                <img src="../../fonts/operation@3x.png"
                     style="margin-right: 10px" />起始地:
                <!-- {{ orderForm.startOrderAddress }} -->
                <div class="host-name-box">
                  <div class="orderHospName">{{orderForm.startOrderAddress}}</div>
                  <div v-show="isEndOrHostAddress(orderForm.startOrderAddress,orderForm.startAddress)"
                       class="endAddress">
                    {{orderForm.startAddress}}
                  </div>
                </div>
              </div>
              <div style="color:#FF7F50;display: flex;align-items: center;margin-top: 10px;">
                <img src="../../images/zd.png"
                     style="margin-right: 10px" />目的地:
                <div class="host-name-box">
                  <div class="orderHospName">{{orderForm.orderHospName}}</div>
                  <div v-show="isEndOrHostAddress(orderForm.endAddress,orderForm.orderHospName)"
                       class="endAddress">
                    {{orderForm.endAddress}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
          <el-button type="primary"
                     @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 地图选点 -->
      <el-dialog :visible.sync="mapVisible"
                 class="propertyDialog"
                 :close-on-click-modal="false"
                 @close="iframeDialogClose()">
        <div class="digLogCont">
          <iframe id="mapPage"
                  width="100%"
                  height="100%"
                  frameborder="0"
                  src="https://mapapi.qq.com/web/mapComponents/locationPicker/v/index.html?search=1&type=1&key=G7BBZ-UP7LU-S3KVH-BCBOP-KZZZO-PABAO&referer=myapp&policy=1"></iframe>
        </div>
        <div slot="footer"
             class="dialog-footer">
          <el-button @click="mapVisible = false">取消</el-button>
          <el-button type="primary"
                     @click="confirmMapAddress()">确认</el-button>
        </div>
      </el-dialog>
      <transition name="el-zoom-in-bottom">
        <div v-show="show4"
             class="transition-box">
          <p class="title">
            【新】消息提示
            <span class="el-icon-close"
                  @click="closeMes"></span>
          </p>
          <p class="content">
            您有{{ total }}条待处理的订单，请及时派单!
          </p>
        </div>
      </transition>
    </div>
    <BaiDuMap  ref="bmapAddressSelect" @confirmMapAddress="confirmMapAddress"></BaiDuMap>

  </div>
</template>

<script>
import _ from 'lodash'
import { isEndOrHostAddress } from '@/utils/addressMet'
import WTimer from '@/components/dateCpn/dataPiceker'
import BaiDuMap from '@/components/Baidu-map.vue'
export default {
  name: 'orderScheduling',
  components: {
    WTimer,
    BaiDuMap
  },
  computed: {},
  data () {
    return {
      form: {
        // startOrderDate: new Date() - 3600 * 1000 * 24 * 30,
        // endOrderDate: new Date()
      },
      isEndOrHostAddress: isEndOrHostAddress,
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7// 如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      isflag: true, // 配送方法是否请求完成
      disableValue: true,
      clientList: [], // 订单列表
      Selected: false, // 是否选择表格对象
      stautsjc: false, // 判断是否选了区域和机构
      paging: {
        pageSize: 10, // 每页显示数据条数
        pageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      editDialogFormVisible: false, // 控制编辑弹出框的显示与隐藏
      addDialogFormVisible: false, // 控制添加客户弹出框的显示与隐藏
      allocationDialogFormVisible: false, // 控制分配弹出框的显示与隐藏
      orderDialogFormVisible: false,
      editForm: {}, // 编辑弹出框表单
      allotForm: {
        nursePersonId: [], // 随车护士Id
        carryPersonId: [],
        area: '',
        orderCompany: '',
        bargainMoney: '',
        escorts: [],
        isPick: '0',
        startHospName: '',
        startDepartment: '',
        startBed: '',
        salesman: '',
        isReturnOrder: '0'
      }, // 分配弹出框表单
      showallot: {},
      addForm: {
        endAddress: '',
        startAddress: '',
        isStretcher: false,
        stretcherNum: 0,
        stretcherPersonNum: 0,
        isReplace: '0',
        isPick: '0',
        isReturnOrder: '0',
        orderType: '0'
      }, // 添加客户弹出框表单
      isReplace: false,
      TransportType: [], // 转运类型
      carNumber: [], // 车牌号
      driverNumber: [], // 司机
      doctor: [], // 医生
      nurse: [], // 护士
      lifting: [], // 搬抬人员
      options: [], // 区域
      orderCompany: [], // 机构
      escorts: [], // 护送人员
      orderForm: {},
      addFormRules: {
        memberName: [
          {
            required: true,
            message: '请输入用户姓名',
            trigger: 'blur'
          }
        ],
        memberMobile: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确格式的电话号码',
            trigger: 'blur'
          }
        ],
        TransportType: [
          {
            required: true,
            message: '请选择转运类型',
            trigger: 'blur'
          }
        ],
        area: [
          {
            required: true,
            message: '请选择区域',
            trigger: 'blur'
          }
        ],
        orderCompany: [
          {
            required: true,
            message: '请选择机构',
            trigger: 'blur'
          }
        ],
        transportTypeId: [
          {
            required: true,
            message: '请选择转运类型',
            trigger: 'blur'
          }
        ],
        carNum: [
          {
            required: true,
            message: '请选择车辆',
            trigger: 'blur'
          }
        ],
        driverName: [
          {
            required: true,
            message: '请选择司机',
            trigger: 'blur'
          }
        ],
        startAddress: [
          {
            required: true,
            message: '请输入起始地点',
            trigger: 'blur'
          }
        ],
        endAddress: [
          {
            required: true,
            message: '请输入目的地',
            trigger: 'blur'
          }
        ]
      }, // 添加客户表单验证规则
      editFormRules: {
        userName: [
          {
            required: true,
            message: '请输入用户姓名',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 8,
            message: '长度在 2 到 8 个字符',
            trigger: 'blur'
          }
        ],
        iphone: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确格式的电话号码',
            trigger: 'blur'
          }
        ],
        TransportType: [
          {
            required: true,
            message: '请选择转运类型',
            trigger: 'blur'
          }
        ],
        origin: [
          {
            required: true,
            message: '请选择出发地',
            trigger: 'blur'
          }
        ],
        destination: [
          {
            required: true,
            message: '请选择目的地',
            trigger: 'blur'
          }
        ],
        appointmentTime: [
          {
            required: true,
            message: '请选择预约时间',
            trigger: 'blur'
          }
        ],
        removeFloor: [
          {
            required: true,
            message: '请输入搬抬楼层',
            trigger: 'blur'
          }
        ],
        removeNumber: [
          {
            required: true,
            message: '请输入搬抬人数',
            trigger: 'blur'
          }
        ]
      }, // 编辑资料表单验证规则
      allotFormRules: {
        carNum: [
          {
            required: true,
            message: '请选择车牌号',
            trigger: 'blur'
          }
        ],
        transportTypeId: [
          {
            required: true,
            message: '请选择转运类型',
            trigger: 'blur'
          }
        ],
        area: [
          {
            required: false,
            message: '请选择区域',
            trigger: 'blur'
          }
        ],
        orderCompany: [
          {
            required: true,
            message: '请选择机构',
            trigger: 'blur'
          }
        ],
        carPersonId: [
          {
            required: true,
            message: '请选择司机工号',
            trigger: 'blur'
          }
        ],
        memberMobile: [
          {
            required: true,
            message: '请选择联系电话',
            trigger: 'blur'
          }
        ],
        doctor: [
          {
            required: true,
            message: '请选择随车医生',
            trigger: 'blur'
          }
        ],
        nurse: [
          {
            required: true,
            message: '请选择随车护士',
            trigger: 'blur'
          }
        ],
        lifting: [
          {
            required: true,
            message: '请选择搬抬人员',
            trigger: 'blur'
          }
        ],
        isPick: [
          {
            required: true,
            message: '请选择是否收取接送费',
            trigger: 'blur'
          }
        ]
      }, // 分配订单表单验证规则
      props: {
        label: 'province',
        value: 'uuid',
        children: 'children'
      },
      limitList: [], // 选择的省市区ID
      corpId: '', // 所选机构
      isDisable: true,
      mapVisible: false,
      active: '',
      PatientData: false,
      bargaining: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      tipsorderds: 0,
      tipsordergs: 0,
      cur: 0,
      orderSum: 0,
      da: '', // 判断输入起点还是终点
      adress: {
        a: '',
        b: ''
      }, // 起点信息
      adressed: {
        a: '',
        b: ''
      }, // 终点信息
      moneyForm: {}, // 费用表单
      isVerification: {
        carNum: {
          title: '车牌号码',
          text: '',
          isMandatory: true,
          is: false,
          input: {
            carNum: ''
          },
          data: null,
          tableData: []
        },
        driverName: {
          title: '司机名称',
          text: '',
          isMandatory: true,
          is: false,
          input: {
            driverName: ''
          },
          data: null,
          tableData: []
        },
        nursePerson: {
          title: '随车护士',
          text: '',
          isMandatory: true,
          is: false,
          input: {
            nursePersonId: ''
          },
          data: null,
          tableData: []
        }
      }
    }
  },
  created () {
    // this.orders()
    this.getAllClient() // 获取所有订单
    // this.getProvince() // 获取省市区
    this.getAllCar() // 获取转运类型

    this.getorderCompany()// 获取机构列表
    // this.orderTimer()
    // this.getAllEscorts(0)
    // this.getAllCarNumber(0)
    // this.getAlldoctor(0)
    // this.getAllnurse(0)
    // this.getAlllifting(0)
    // this.getAlldriverNumber(0)
  },
  beforeDestroy () {
    clearInterval(window.timer)
    this.$eventBus.$off('messageOder')
  },
  mounted () {
    window.latlng = {
      endAddress: '',
      startAddress: ''
    }
    this.$eventBus.$on('messageOder', () => {
      this.getAllClient()
    })
  },
  destroyed () {
    clearInterval(window.timer)
    // console.log('guanbi')
  },

  methods: {
    /** 确认地图地址 */
    confirmMapAddress (addressInfo) {
    //   console.log(addressInfo) // 获取到选点的地址和经纬度
      const { latitude, longitude, province, district, address, searchAddresKeywords } = addressInfo
      //   console.log(latitude, longitude)
      const latlng = {
        lat: latitude,
        lng: longitude
      }
      if (this.da === '1') {
        this.addForm.endAddress = searchAddresKeywords// province + district + address
        window.latlng.endAddress = latlng
        this.countMoney()
      } else {
        this.addForm.startAddress = searchAddresKeywords// province + district + address
        window.latlng.startAddress = latlng
        this.countMoney()
      }
      this.$forceUpdate()
      //   console.log(this.da, this.addForm, window.latlng, 'this.addForm1997')
      this.mapVisible = false
    },

    reset () {
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.$refs.form.resetFields()
      this.$refs.WTimer.resetFields()
      this.search()
    },
    search () {
      this.paging.pageSize = 10
      this.paging.pageNo = 1
      this.getAllClient()
    },
    orders () {
      window.timer = setInterval(async () => {
        this.getAllClient()
        console.log('每15秒刷新新增订单-当前')
      }, 15000)
    },
    playSound () {
      var url = 'http://img.i12320.com/newOrder.mp3'
      var n = new Audio(url)
      n.src = url
      n.play() // 播放阅读
      //  n.cancel();  //取消阅读
      //  n.pause();  //暂停阅读
    },
    changebargainMoney (event) {
      // 限制不能输入 00.11 的格式
      if (
        this.allotForm.bargainMoney.split('')[0] == '0' &&
        this.allotForm.bargainMoney.split('')[1] != '.'
      ) {
        this.allotForm.bargainMoney = ''
        return
      }
      this.allotForm.bargainMoney = event
        .toString()
        .match(/^\d*(\.?\d{0,2})/g)[0]
    },
    // 车牌号码搜索方法
    async searchCarNum (data) {
      //   if (this.allotForm.area == '') {
      //     return this.$message.error('请先选择区域')
      //   } else
      if (this.allotForm.orderCompany == '') {
        return this.$message.error('请先选择机构')
      } else {
        this.stautsjc = true
        const { data: res } = await this.$http.post(
          '/userManageServer/driver/selectDriverNameAndCarNumList',
          {
            carNum: data.input.carNum,
            companyId: this.allotForm.orderCompany
            // "status": 1
          }
        )
        console.log(this.allotForm.orderCompany)
        console.log(res, '车牌号码')
        this.isVerification.carNum.tableData = res.data.CarNumList
      }
    },
    // 车牌确定
    selCarNum (data) {
      this.isVerification.carNum.text = data.carNum
      this.isVerification.carNum.is = false
    },
    // 车牌重置
    clrCarNum () {
      this.isVerification.carNum.input.carNum = ''
      this.isVerification.carNum.text = ''
      this.searchCarNum(this.isVerification.carNum)
    },
    // 随车护士确定
    selCarNursePerson (data) {
      if (
        this.allotForm.transportTypeId !== 4 &&
        this.allotForm.transportTypeId !== 3
      ) {
        // this.allotForm.nurseList = []
        this.allotForm.nursePersonId = []
        this.$message.warning('请选择转运类型---护理护送/监护护送')
        return
      }
      let repetition = false
      if (!this.allotForm.nursePersonId.length) {
        this.isVerification.nursePerson.text = data.DOC_NAME + ' '
        this.allotForm.nursePersonId.push(data.uuid)
      } else {
        this.allotForm.nursePersonId.forEach((item, i) => {
          item == data.uuid ? (repetition = true) : ''
        })
        if (!repetition) {
          this.isVerification.nursePerson.text += data.DOC_NAME + ' '
          this.allotForm.nursePersonId.push(data.uuid)
        }
      }
      console.log(this.allotForm, 'allotForm')
    },
    // 随车护士搜索
    async searchNurse (data) {
      console.log('qwe')
      this.stautsjc = false
      //   if (this.allotForm.area == '') {
      //     return this.$message.error('请先选择区域')
      //   } else
      if (this.allotForm.orderCompany == '') {
        return this.$message.error('请先选择机构')
      } else if (
        this.allotForm.transportTypeId !== 4 &&
        this.allotForm.transportTypeId !== 3
      ) {
        return this.$message.error(
          '请选择转运类型---护理护送/监护护送'
        )
      } else {
        this.stautsjc = true
        const { data: res } = await this.$http.post(
          '/pmService/tInfoDoctor/getTInfoDoctorList',
          {
            companyId: this.allotForm.orderCompany,
            docType: 1,
            onStatus: '1',
            docName: data.input.nursePersonId || ''
          }
        )
        this.nurse = res.data.records
      }
    },
    // 随车护士重置
    clrNurse () {
      this.isVerification.nursePerson.input.nursePersonId = ''
      this.isVerification.nursePerson.text = ''
      this.allotForm.nursePersonId = []
      this.searchNurse(this.isVerification.nursePerson)
    },
    // 司机名称搜索方法
    async searchDriverName (data) {
      //   if (this.allotForm.area == '') {
      //     return this.$message.error('请先选择区域')
      //   } else
      if (this.allotForm.orderCompany == '') {
        return this.$message.error('请先选择机构')
      } else {
        this.stautsjc = true
        const { data: res } = await this.$http.post(
          '/userManageServer/driver/selectDriverNameAndCarNumList',
          {
            driverName: data.input.driverName,
            companyId: this.allotForm.orderCompany
            // "status": 1
          }
        )
        this.isVerification.driverName.tableData =
          res.data.DriverNameList
      }
    },
    selDriverName (data) {
      this.isVerification.driverName.text = data.driverName
      this.isVerification.driverName.id = data.id
      this.isVerification.driverName.is = false
    },
    clrDriverName () {
      this.isVerification.driverName.input.driverName = ''
      this.isVerification.driverName.text = ''
      this.searchDriverName(this.isVerification.driverName)
    },
    // 选择区域后触发
    // ad (v) {
    //   // console.log(v);
    //   this.limitList = v
    //   this.getorderCompany(v)
    // },
    // 选择机构后触发
    async dd (v) {
      console.log('选择机构后触发', v)
      this.disableValue = false
      if (v.orderCompany === '') {
        const a = this.addForm.area
        this.carNumber = []
        this.driverNumber = []
        this.doctor = []
        this.nurse = []
        this.lifting = []
        this.escorts = []
        this.addForm = {
          isPick: '0',
          isReturnOrder: '0',
          applicationCode: '1'
        }
        this.addForm.area = a
      } else {
        const selectedItem = this.orderCompany.find((item) => {
          // 这里的chargingWorkNameList就是上面遍历的数据源
          return item.corpId === v.orderCompany
          // 筛选出匹配数据，是对应数据的整个对象
        })
        this.allotForm.orderCompanyName = selectedItem.companyName
        this.allotForm.orderCompany = v.orderCompany
        // 获取所有护送员
        this.getAllEscorts(v)
        // 获取车牌号
        this.getAllCarNumber(v)
        // 获取随车医生
        this.getAlldoctor(v)
        // 获取随车护士
        this.getAllnurse(v)
        // 获取所有搬抬人员
        this.getAlllifting(v)
        // 获取司机
        // this.getAlldriverNumber(v)
      }
    },
    // 监听转运类型的change事件
    TransportTypeChange (v) {
      var that = this
      console.log(v)
      switch (v) {
        case 1:
          // that.addForm.doctorList = ''
          // that.addForm.nurseList = ''
          // that.addForm.escortList = ''
          that.allotForm.doctorPersonId = []
          that.allotForm.nursePersonId = []
          that.allotForm.escorts = []
          that.isVerification.nursePerson.text = ''
          break
        case 2:
          // that.addForm.doctorList = []
          // that.addForm.nurseList = []
          that.allotForm.doctorPersonId = []
          that.allotForm.nursePersonId = []
          that.isVerification.nursePerson.text = ''
          break
        case 3:
          // that.addForm.doctorList = []
          // that.addForm.escortList = []
          that.allotForm.doctorPersonId = []
          that.allotForm.escorts = []
          break
        case 4:
          // that.addForm.escortList = []
          that.allotForm.escorts = []
          break
      }
    },
    // 监听护士的change事件
    selectNurse (v) {
      if (
        this.allotForm.transportTypeId !== 4 &&
        this.allotForm.transportTypeId !== 3
      ) {
        // this.allotForm.nurseList = []
        this.allotForm.nursePersonId = []
        return this.$message.warning(
          '请选择转运类型---护理护送/监护护送'
        )
      }
    },
    selectEscort (v) {
      // if (v === 0) {
      if (this.allotForm.transportTypeId !== 2) {
        this.allotForm.escorts = []
        this.$message.warning('请选择转运类型---高级护送')
      }
      // } else {
      //   if (this.allotForm.transportTypeId !== 2) {
      //     this.allotForm.escortList = []
      //     this.$message.warning('请选择转运类型---高级护送')
      //   }
      // }
    },
    selectDoc (v) {
      // if (v === 0) {
      //   console.log(this.addForm.transportTypeId)
      if (this.allotForm.transportTypeId !== 4) {
        this.allotForm.doctorPersonId = []
        this.$message.warning('请选择转运类型---监护护送')
      }
      // } else {
      //   console.log(this.allotForm.transportTypeId)
      //   if (this.allotForm.transportTypeId !== 4) {
      //     this.allotForm.doctorList = []
      //     this.$message.warning('请选择转运类型---监护护送')
      //   }
      // }
    },

    // 获取所有订单列表--调试完成
    async getAllClient () {
      var that = this
      // 新改 /userManageServer/pay/selectOrderDispatchList
      // /userManageServer/pay/selectOrderList
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/selectOrderDispatchList',
        {
          status: 0,
          pageSize: this.paging.pageSize,
          pageNo: this.paging.pageNo,
          ...this.form
        }
      )
      this.total = +res.pageBean.pageDataCount
      console.log(res)
      if (res.statusCode !== '200') { return this.$message.error('获取订单列表失败了') }
      this.clientList = res.data
      if (res.data.length > this.tipsorderds) {
        that.tipsorderds = res.data.length
        that.tipsordergs = res.data.length - 1
      } else {
        that.tipsorderds = res.data.length
        that.tipsordergs = res.data.length
      }
      this.orderSum = res.data.length
      if (res.data.length > 0) {
        if (that.tipsorderds > that.tipsordergs) {
          //   this.playSound()
          that.tipsordergs = that.tipsordergs + 1
          this.orderSum = res.data.length
          //   this.show4 = true
        }
        // if (JSON.stringify(res.data.sort()) != JSON.stringify(this.clientList.sort())) {
      } else {
        if (res.data.length < 1) {
          this.show4 = false
        }
      }
    },
    // 获取车牌号--调试完成
    async getAllCarNumber (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoVehicle/getTInfoVehicleList',
        {
          corpId: v.orderCompany,
          onStatus: '1'
        }
      )
      console.log('选择车牌号码id====' + v.orderCompany)
      if (res.statusCode !== '200') { return this.$message.error('获取车辆列表失败') }
      this.carNumber = res.data
    },
    // 获取所有转运类型
    async getAllCar () {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoTransportType/getTInfoTransportTypeList',
        {
          status: 1
        }
      )
      if (res.statusCode !== '200') { return this.$message.error('获取转运类型列表失败') }
      this.TransportType = res.data.records
    },
    // 获取司机--调试完成
    async getAlldriverNumber (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDriver/getTInfoDriverList',
        {
          companyId: v.orderCompany,
          onStatus: '1'
        }
      )
      // console.log(res,1111);
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取司机失败') }
      this.driverNumber = res.data
    },
    // 获取随车医生--调试完成
    async getAlldoctor (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 0,
          onStatus: '1'
        }
      )
      if (res.statusCode !== '200') { return this.$message.error('获取医生列表失败') }
      this.doctor = res.data.records
      console.log(res)
    },
    // 获取随车护士--调试完成
    async getAllnurse (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 1,
          onStatus: '1'
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取随车护士失败') }
      this.nurse = res.data.records
      console.log(res, '获取随车护士')
    },
    // 获取所有搬抬人员--调试完成
    async getAlllifting (v) {
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 3,
          onStatus: '1'
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取搬抬人员失败') }
      this.lifting = res.data.records
      // console.log(res);
    },
    // 获取所有护送员--调试完成
    async getAllEscorts (v) {
      console.log(v, 'v')
      // console.log(this.allotForm.orderCompany);
      const { data: res } = await this.$http.post(
        '/pmService/tInfoDoctor/getTInfoDoctorList',
        {
          companyId: v.orderCompany,
          docType: 2,
          onStatus: '1'
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取护送员失败') }
      this.escorts = res.data.records
      console.log(res, '获取所有护送员')
    },
    // 获取省份--调试完成
    async getProvince () {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectProvinceList',
        {}
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取省份失败') }
      this.options = res.data
      // console.log(res);
      this.options.forEach(async (item) => {
        item.children = await this.getCity(item.uuid)
        item.children.forEach(async (i) => {
          i.children = await this.getDistrict(i.uuid)
        })
      })
    },
    // 获取市--调试完成
    async getCity (e) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectCityList',
        {
          province: e
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取市失败') }
      return res.data
    },
    // 获取区域--调试完成
    async getDistrict (e) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectDistrictList',
        {
          cityId: e
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取区域失败') }
      return res.data
    },
    // 按地区获取机构--调试完成
    async getorderCompany (e) {
      const { data: res } = await this.$http.post(
        '/userManageServer/tSysCompany/selectCompanyByArea',
        {
          //   cityId: e[1],
          //   district: e[2]
          pageNo: 1,
          pageSize: 10000
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('获取机构失败') }
      this.orderCompany = res.data
    //   console.log(this.orderCompany)
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getAllClient()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.pageNo = PageNum
      await this.getAllClient()
    },
    // 点击进行定位
    location () {
      console.log('定位')
    },
    // 格式化时间
    formatTime: function (date, fmt) {
      var date = new Date(date)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          var str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? str
              : ('00' + str).substr(str.length)
          )
        }
      }
      return fmt
    },
    // 添加客户--确定按钮
    async addClient () {
      if (this.addForm.date1 && this.addForm.date2) {
        this.addForm.orderReserveDate =
          this.formatTime(this.addForm.date1, 'yyyy-MM-dd') +
          ' ' +
          this.formatTime(this.addForm.date2, 'hh:mm:ss')
      }
      console.log(this.addForm, 'this.addForm')
      // if (this.addForm.isStretcher) {
      //   this.addForm.isStretcher = '1'
      // } else {
      //   this.addForm.isStretcher = '0'
      // }
      // console.log(this.addForm);
      // this.addForm.startLongitude = window.latlng.startAddress.lng + ''
      // this.addForm.startLatitude = window.latlng.startAddress.lat + ''
      // this.addForm.endLongitude = window.latlng.endAddress.lng + ''
      // this.addForm.endLatitude = window.latlng.endAddress.lng + ''
      // this.addForm.startOrderAddress = this.addForm.startAddress
      // this.addForm.orderHospName = this.addForm.endAddress
      // this.addForm.applicationCode = "10000",
      //   this.addForm.status = '0'
      // // 预校验
      // this.$refs.addForm.validate(async valid => {
      // if (!valid) return
      // 判断是否填入必填项
      if (!this.addForm.hasOwnProperty('startAddress')) { return this.$message.warning('请填写起始位置') }
      if (!this.addForm.hasOwnProperty('endAddress')) { return this.$message.warning('请填写目的位置') }
      if (this.active === '') { return this.$message.warning('请选择转运类型') }
      if (
        !this.addForm.hasOwnProperty('memberName') ||
        this.addForm.memberName === ''
      ) {
        return this.$message.warning('请输入姓名')
      }
      if (
        !this.addForm.hasOwnProperty('memberMobile') ||
        this.addForm.memberMobile === ''
      ) {
        return this.$message.warning('请输入电话号码')
      }
      if (this.addForm.orderType == 1) {
        if (
          !this.addForm.hasOwnProperty('orderReserveDate') ||
          this.addForm.orderReserveDate === ''
        ) {
          return this.$message('请选择预约时间')
        }
      }
      if (!this.isflag) {
        return this.$message.warning('请稍等，费用暂未更新')
      }
      this.addForm.endLatitude = window.latlng.endAddress.lat
      this.addForm.endLongitude = window.latlng.endAddress.lng
      this.addForm.startLatitude = window.latlng.startAddress.lat
      this.addForm.startLongitude = window.latlng.startAddress.lng
      if (this.addForm.isStretcher == true) {
        this.addForm.isStretcher = '1'
      } else {
        this.addForm.isStretcher = '0'
        this.addForm.stretcherNum = 0
        this.addForm.stretcherPersonNum = 0
      }

      this.addForm.status = 0
      //  "form": window.latlng.startAddress.lat + ',' + window.latlng.startAddress.lng
      //   endLongitude: window.latlng.endAddress.lat + ',' + window.latlng.endAddress.lng
      this.addForm.orderHospName = this.addForm.endAddress
      this.addForm.transportTypeId = this.active
      this.addForm.createBy = sessionStorage.getItem('userId')
      //   // 调接口，发送添加客户请求
      //   console.log(this.addForm)
      //   return false
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/addOrderByManage',
        JSON.parse(JSON.stringify(this.addForm))
      )
      console.log(res)
      if (res.statusCode !== '200') { return this.$message.error('添加订单失败') }
      this.$message({
        message: '添加订单成功',
        type: 'success',
        duration: '1000'
      })
      //   // 重新渲染数据
      this.getAllClient()
      //   // 关闭弹框
      this.addDialogFormVisible = false
      // })
    },
    // 监听添加客户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
      this.show1 = false
      this.show2 = false
      this.isReplace = false
      this.addForm = {
        isStretcher: false,
        stretcherNum: 0,
        stretcherPersonNum: 0,
        isReplace: '0',
        isPick: '0',
        orderType: '0',
        isReturnOrder: '0'
      } // 添加客户弹出框表单
    },
    // 点击编辑按钮触发
    edit () {
      // 判断是否选择编辑对象
      if (this.editForm.userName === '') { return this.$message.error('请先选择编辑对象') }
      // 显示编辑弹出框
      this.editDialogFormVisible = true
    },
    // 编辑客户--确定按钮
    editClient () {
      // 预校验
      this.$refs.editForm.validate(async (valid) => {
        // if (!valid) return
        // 调接口，发送编辑客户信息请求
        // const { data } = await this.$http.post('', this.addForm)
        // console.log(data)
        // 判断请求是否成功
        // if (data.sattus !== '200') return this.$message.error('添加客户失败')
        // 重新渲染数据
        // this.getAllClient()
        // 关闭弹框
        // this.editDialogFormVisible = false
      })
    },
    // 监听编辑客户对话框的关闭事件
    editDialogClose () {
      this.$refs.editForm.resetFields()
    },
    // 点击分配按钮触发
    allot (v) {
      // 判断是否选择分配对
      this.allotForm.orderNo = v.orderNo
      this.showallot = v
      const { startHospName, startDepartment, startBed } = v

      if (startHospName !== null) {
        this.$set(this.allotForm, 'startHospName', startHospName)
        // this.allotForm.startHospName = startHospName
      }

      if (startDepartment !== null) {
        this.$set(this.allotForm, 'startDepartment', startDepartment)
        // this.allotForm.startDepartment = startDepartment
      }

      if (startBed !== null) {
        this.$set(this.allotForm, 'startBed', startBed)
        // this.allotForm.startBed = startBed
      }

      console.log(this.allotForm, 'this.showallot')
      if (this.showallot.transportTypeId) {
        this.allotForm.transportTypeId = this.showallot.transportTypeId
      }
      // if (this.allotForm.iphone === '') return this.$message.error('请先选择需要分配的订单')
      // 显示分配弹出框
      this.allocationDialogFormVisible = true

      //   this.allotForm.isPick = '0'
    },
    // 分配订单--确定按钮
    allotClient () {
      // return console.log('分配')
      // 预校验
      this.$refs.allotForm.validate(async (valid) => {
        if (!valid) return
        if (this.isVerification.carNum.text == '') { return this.$message.error('车牌号码为必选项') }
        if (this.isVerification.driverName.text == '') { return this.$message.error('司机名称为必选项') }
        this.allotForm.status = '1'
        this.allotForm.carNum = this.isVerification.carNum.text
        this.allotForm.driverName = this.isVerification.driverName.text
        this.allotForm.carPersonId = this.isVerification.driverName.id
        // console.log(JSON.parse(JSON.stringify(this.allotForm)));
        this.allotForm.createTime = new Date(
          Date.parse(this.allotForm.createTime)
        )
        const loading = this.$loading({
          lock: true,
          text: '正在加速分配中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        // this.allotForm.nursePersonId = ''
        this.allotForm.createBy = sessionStorage.getItem('userId')

        console.log(this.allotForm, 'this.allotForm')
        // return false
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post(
          '/userManageServer/dispatch/addOrderDispatch',
          JSON.parse(JSON.stringify(this.allotForm))
        ).finally(() => {
          loading.close()
        })
        // 判断请求是否成功
        if (res.statusCode == '200') {
          this.$notify({
            title: '调度通知',
            message: '分配订单成功',
            type: 'success',
            duration: 3000
          })
          // 成功提示

          // 重新渲染数据
          this.getAllClient()
          console.log(this.allotForm.carNum, this.isVerification.carNum)
          this.isVerification.carNum.input.carNum = ''
          this.isVerification.carNum.text = ''
          this.allotForm.carNum = ''
          // 关闭弹框
          this.allocationDialogFormVisible = false
        }
      })
    },
    // 监听分配订单对话框的关闭事件
    allootDialogClose () {
      this.$refs.allotForm.resetFields()
      this.allotForm = {
        carNum: '', // 车牌号
        carPersonId: '', // 司机
        iphone: '', // 电话
        doctorPersonId: [], // 医生
        nursePersonId: [], // 护士
        carryPersonId: [], // 搬抬人员
        orderCompany: '', // 机构id
        area: '', // 区域
        escorts: [], // 护送人员
        createBy: 1, // 派单者ID
        orderCompanyName: '', // 机构名字------
        orderNo: '', // 订单流水号
        status: '0',
        isPick: '0',
        isReturnOrder: '0'
      }
      this.isVerification.driverName.text = ''
      this.isVerification.nursePerson.text = ''
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisable = true
      this.orderForm = v
      this.orderDialogFormVisible = true
    },
    // 关闭查看详情按钮触发
    sure () {
      this.orderDialogFormVisible = false
    },
    // 地图监听
    mapListener (v) {
      console.log('地图监听')
      const that = this
      window.addEventListener(
        'message',
        function (event) {
          // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
          const mapData = event.data
          if (mapData && mapData.module == 'locationPicker') {
            that.mapData = mapData
          }
        },
        false
      )
    },
    // 地图选点确认按钮
    confirmMapData () {
      console.log(this.da, 'this.da ')
      if (this.da === '1') {
        this.addForm.endAddress = this.adress.a
        window.latlng.endAddress = this.adress.b
        this.countMoney()
      } else {
        this.addForm.startAddress = this.adressed.a
        window.latlng.startAddress = this.adressed.b
        this.countMoney()
      }
      this.mapVisible = false
    },
    // 监听地图选点的关闭事件
    iframeDialogClose () {
      window.removeEventListener('message', this.abc, false)
      this.adress = {
        a: '',
        b: ''
      }
      this.adressed = {
        a: '',
        b: ''
      }
    },
    // 点击起始地选择时触发
    selectAddress (v) {
      this.da = v
      //   this.mapVisible = true
      this.$refs.bmapAddressSelect.show()
      window.addEventListener('message', this.abc, false)
    },
    abc (event) {
    //   console.log(event, 'event')
      var that = this
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      const mapData = event.data
      if (mapData && mapData.module == 'locationPicker') {
        that.mapData = mapData
      }
      //   console.log(event)
      if (this.da === '1') {
        this.adress.a = event.data.poiaddress + event.data.poiname
        this.adress.b = event.data.latlng
        // console.log('终点', this.adress.a);
        // that.addForm.endAddress = event.data.poiname
        // window.latlng.endAddress = event.data.latlng
      } else {
        this.adressed.a = event.data.poiaddress + event.data.poiname
        this.adressed.b = event.data.latlng
        // console.log('起点', this.adressed.a);
        // that.addForm.startAddress = event.data.poiname
        // window.latlng.startAddress = event.data.latlng
      }
    },
    // 选择转运类型时触发
    checkTransType (v) {
      this.active = v
      this.countMoney()
    },
    // 监听是否选择搬抬服务
    isStretcherChange () {
      // this.show1 = !this.show1
      if (this.addForm.isStretcher) {
        this.show1 = true
        this.addForm.stretcherNum = 1
        this.addForm.stretcherPersonNum = 1
        this.countMoney()
      } else {
        this.show1 = false
        this.countMoney()
        this.addForm.stretcherNum = ''
        this.addForm.stretcherPersonNum = ''
      }
      console.log(this.addForm.stretcherNum)
      console.log(this.addForm.stretcherPersonNum)
    },
    // 监听是否代叫
    isReplaceChange () {
      if (this.isReplace) {
        this.show2 = true
      } else {
        this.show2 = false
      }
    },
    // 监听是否填写病人资料
    PatientDataChange () {
      if (this.PatientData) {
        this.show3 = true
      } else {
        this.show3 = false
      }
    },
    // 监听是否议价
    bargainingChange (v) {
      if (this.bargaining) {
        this.show5 = true
      } else {
        this.show5 = false
        this.allotForm.bargainMoney = ''
      }
    },
    // 点击提前按钮触发
    async ahead (v) {
      console.log(v)
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/updateOrderStatusInAdvance',
        {
          orderNo: v.orderNo
        }
      )
      if (res.statusCode !== '200') { return this.$message.error('提前完成失败') }
      // 成功提示
      this.$message.success('提前完成成功')
      this.getAllClient()
    },
    // 取消订单
    async refund (v) {
      console.log(v)
      if (v.applicationCode == '10004') { return this.$message.error('暂不支持取消') }
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/addOrderCance',
        {
          orderNo: v.orderNo,
          isBackCancel: true,
          createBy: sessionStorage.getItem('userId'),
          cancelReason: '后台原因'
        }
      )
      if (res.statusCode !== '200') { return this.$message.error('取消订单失败') }
      // 成功提示
      this.$message.success('取消订单成功')
      this.getAllClient()
    },
    // 计算费用
    async countMoney () {
      console.log(this.addForm, this.active, 'this.addForm')
      if (
        !this.addForm.hasOwnProperty('startAddress') ||
        !this.addForm.hasOwnProperty('endAddress') ||
        this.active === ''
      ) { return }
      var tstretcherCount = 0
      if (this.show1) {
        tstretcherCount =
          this.addForm.stretcherNum * this.addForm.stretcherPersonNum
      } else {
        tstretcherCount = 0
      }
      this.isflag = false
      console.log(window.latlng, 'window.latlng')
      const formLat = window.latlng.startAddress.lat + ',' + window.latlng.startAddress.lng
      const toLat = window.latlng.endAddress.lat + ',' + window.latlng.endAddress.lng
      if (!window.latlng.startAddress.lat || !window.latlng.endAddress.lat) {
        this.$message.error('请选择出发地点')
        return false
      }
      const loading = this.$loading({
	    lock: true,
	    text: '准备获取数据中，请稍候',
	    spinner: 'el-icon-loading'
	  })
      const { data: res } = await this.$http.post(
        '/userManageServer/pay/selectTransportMap',
        {
          form: formLat,
          to: toLat,
          transportTypeId: this.active,
          tstretcherCount: tstretcherCount
        }
      ).catch((err) => {
        this.isflag = true
        console.log(err)
      })
      this.isflag = true
      loading.close()
      this.moneyForm = res.data
      if (this.moneyForm.predictMile < 0) {
        this.moneyForm.predictMile = 0
      }
    },
    // 关闭消息弹窗
    closeMes () {
      this.show4 = false
    },
    orderTimer () {
      window.timer = setInterval(async () => {
        const { data: res } = await this.$http.post(
          '/userManageServer/pay/selectOrderList',
          {
            status: 0,
            pageSize: this.paging.pageSize,
            pageNo: this.paging.pageNo
          }
        )

        if (
          JSON.stringify(res.data.sort()) ===
          JSON.stringify(this.clientList.sort())
        ) {
          console.log('一样')
          this.show4 = false
        } else {
          this.clientList = res.data
          this.orderSum = res.data.length
          this.show4 = true
          console.log('不一样')
        }
      }, 3000)
    },
    popoverShowClick () { }
  }
}
</script>

<style lang="less" scoped>
.orderScheduling {
  width: 100%;
  height: 100%;
  // padding: 0.375rem 0.25rem 0.5rem;
  padding: 10px;
  // width: 100%;
  // min-height: 100vh;
  // // height:100%;
  // // padding: 0.375rem 0.25rem 0.5rem;
  // padding: 10px;

  /deep/ .father-card {
    // position: relative;
    width: 100%;
    height: 100%;

    /deep/ .el-card__body {
      position: relative;
      // padding-bottom: 0;
      height: 100%;
      width: 100%;
    }

    .transition-box {
      position: absolute;
      // border: 1px solid red;
      right: 10px;
      // top:0px;
      bottom: 0px;
      width: 400px;
      // background-color: pink;
      border: 4px solid #1e90ff;
      height: 150px;
      z-index: 999;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        text-align: center;
        // margin-bottom: 10px;
        background-color: #1e90ff;
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;

        span {
          width: 20px;
          height: 20px;
          display: inline-block;
          // background-color: red;
          margin-right: 10px;
        }
      }

      .content {
        width: 100%;
        height: 90px;
        padding-left: 5px;
        background-color: #fff;
        padding-top: 10px;
      }
    }

    .el-row {
      width: 100%;
      margin-bottom: 0.1625rem;
    }

    .addresscolor {
      // background-color: #F3F6FA;
      // padding: 20px 50px;
      font-weight: bold;
      font-size: 18px;

      .addresscolor-left {
        display: flex;
        height: 45px;
        line-height: 45px;
        color: #0097fe;

        input {
          color: #0097fe;
        }
      }

      .addresscolor-right {
        display: flex;
        height: 45px;
        line-height: 45px;
        color: #ff7f50;

        input {
          color: #ff7f50;
        }
      }

      input {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .liftingcolor {
      display: flex;
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      margin-top: 10px;
      background-color: #f3f6fa;
      justify-content: space-between;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .liftingcolor-right {
        text-align: center;
      }
    }

    .cflex {
      display: flex;
    }

    .daijiaocolor {
      display: flex;
      font-size: 18px;
      height: 45px;
      line-height: 45px;
      justify-content: space-between;

      .daijiaocolor-left {
        margin-right: 20px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      input {
        height: 45px;
        width: 300px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
      }
    }

    .ordertypecolor {
      display: flex;
      margin-top: 10px;
      align-items: center;

      .ordertypecolor-left {
        font-size: 18px;
        margin-right: 12px;
      }

      img {
        width: 18px;
        height: 18px;
        margin-right: 12px;
      }
    }

    .yuyuetime {
      margin-top: 15px;
      // text-align: right;

      input {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        background-color: #f3f6fa;
      }
    }

    .nexpersoncolor {
      display: flex;
      justify-content: space-between;
      height: 45px;
      line-height: 45px;
      font-size: 18px;
      background-color: #f3f6fa;

      .nexpersoncolor-left {
        margin-right: 20px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      input {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        background-color: #f3f6fa;
      }
    }

    .patientcolor {
      // display: flex;
      font-size: 18px;
      height: 45px;
      line-height: 45px;

      .patientcolor-left {
      }
    }

    .patientcolor-right {
      text-align: center;

      input {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
      }
    }

    .addcolor {
      background-color: #f3f6fa;
      text-align: center;
      width: 100%;
      padding: 20px 50px;
      margin-top: 10px;

      .addflex {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        font-size: 20px;
        margin-top: 5px;

        .flex-right {
          font-weight: bold;
        }
      }

      .addbutooncolor {
        border-bottom: 1px solid #666666;
      }

      .addsizecolor {
        font-size: 18px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .fpddcolor {
      background-color: #f3f6fa;
      padding: 10px 10px;
      // line-height: 30px;
      font-weight: bold;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      .fpddcolor-title {
        border-bottom: 1px solid #666666;
        font-size: 18px;
        padding-bottom: 10px;
      }

      .fpddcolor-top {
        display: flex;
      }

      .fpddcolor-in {
        // font-size: 18px;
      }

      .fpddcolor-low {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .fpaddcolor {
      background-color: #f3f6fa;

      .fpaddcolor-tip {
        display: flex;
        justify-content: space-around;
        padding: 0 10px;

        input {
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          background-color: #fff;
          width: 230px;
        }
      }

      .fpaddcolor-in {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
      }

      .fpaddcolor-low {
        text-align: center;
        border-top: 1px solid #fff;
      }
    }

    //分页区域样式
    .el-pagination {
      // position: absolute;
      // right: 0;
      // bottom: 0;
      margin-top: 20px;
      margin-bottom: 0.25rem;
      margin-right: 0.25rem;
      text-align: right;
    }

    // 编辑弹出框样式
    .order-dialog,
    .edit-dialog,
    .allocation-dialog,
    .add-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }

      /deep/ .el-form {
        .subtitle {
          position: relative;
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;

          .el-checkbox {
            text-align: right;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }

          span {
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }

        .el-row {
          display: flex;

          .el-form-item {
            flex: 1;
            display: flex;

            .money {
              input {
                color: red;
              }
            }

            .dw {
              display: inline-block;
              width: 40px;
              height: 40px;
              font-size: 20px;
            }

            .el-form-item__content {
              flex: 1;

              .el-input {
                width: 100%;
                padding-right: 0;

                .el-input__inner {
                  width: 100%;
                }
              }

              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .add-dialog {
      .fipstype {
        font-size: 18px;
        margin-top: 10px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }

      .transportTypeIdBox {
        width: 100%;
        display: flex;
        justify-content: space-around;

        div {
          // flex: 1;
          // width: 150px;
          // height: 50px;
          // line-height: 50px;
          margin-top: 12px;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
          background: #eff2f4;
        }

        .active {
          border: 1px solid #0097fe;
          color: #0097fe;
          font-weight: 700;
        }
      }

      .moneyBox {
        .el-row {
          // display: flex;
          // justify-content: space-between;
          span {
            display: inline-block;
            width: 50%;

            i {
              font-style: normal;
            }
          }

          span:nth-child(2) {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    // 分配弹出框样式
    .allocation-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 18px;
        font-weight: 700;
      }

      //   .el-row {
      //     display: flex;
      //     justify-content: space-between;
      //   }
      //   .dialog-footer {
      //     text-align: center;
      //   }
    }

    .propertyDialog {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);

      // width: 600px;
      // height: 800px;
      // margin: 0 !important;

      .el-dialog {
        // width: 100%;
        // height: 100%;
        // margin: 0 !important;
        // padding: 0;

        // display: flex;
        // flex-direction: column;
        // align-items: center;
        .el-dialog__header {
          height: 0 !important;
          width: 0 !important;

          .el-dialog__headerbtn {
            display: none;
          }
        }

        .el-dialog__body {
          padding: 0 20px;
          height: 700px;

          .digLogCont {
            width: 100%;
            height: 100%;
          }
        }

        .el-dialog__footer {
          padding: 0 20px;
        }
      }
    }
  }
}

.dy-sel {
  display: inline-block;
  align-items: center;
  margin: 0 0.125rem 0.275rem 0;

  > div {
    display: inline-block;
  }

  .sel-title {
    font-size: 0.175rem;
    margin-right: 0.15rem;

    .title-icon {
      color: #f56c6c;
      margin-right: 0.05rem;
    }
  }

  .sel-input {
    width: 2.87rem;
    height: 0.5rem;
    border-radius: 0.05rem;
    border: 0.0125rem solid #dcdfe6;
    box-sizing: border-box;
    padding: 0 0.1875rem;
    line-height: 0.5rem;
  }
}

.dy-content {
  width: 100%;

  .content-head {
    height: 0.5rem;
    // background-color: pink;
    display: flex;
    align-items: center;

    .head-input {
      display: flex;
      align-items: center;
      margin-right: 0.1rem;

      span {
        white-space: nowrap;
        margin-right: 0.05rem;
      }

      .el-input {
        width: 1.5rem;
      }
    }
  }

  .content-table {
    margin-top: 0.2rem;
  }
}

.dycolor {
  color: #dcdfe6 !important;
}
.orderHospName {
  // font-size:22px;
}
.endAddress {
  // font-size: 10px;
  color: #666666;
}
.host-name-box {
  display: flex;
  flex-direction: column;
}
</style>

<template>
    <el-dialog title="位置选择" :visible.sync="openMap"  width="900px" append-to-body>
        <el-form label-width="80px">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="搜索地址">
                        <el-input  type="text" id="searchAddres" v-model="searchAddresKeywords"
                            placeholder="请输入地点">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="14">
                    <el-form-item label="当前地址">
                        <el-input placeholder="" readonly v-model="addressInfo.address">
                            <template
                                slot="prepend">{{addressInfo.province}}{{addressInfo.city}}{{addressInfo.district}}</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div id="baidu-map-container" style="width: 100%; height: 400px;"></div>

        <div slot="footer" class="dialog-footer">
            <el-button :type="isflag?'primary':'info'" @click="confirmSelect">确 定</el-button>
            <el-button @click="cancel">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      isflag: false,
      searchAddresKeywords: '',
      addressInfo: {
        // 地址信息
        longitude: '', // 经度
        latitude: '', // 纬度
        province: '', // 省
        city: '', // 市
        district: '', // 区
        address: '', // 详细地址
        searchAddresKeywords: ''
      },
      openMap: false,
      ac: null

    }
  },
  methods: {
    // 初始化百度地图
    initBaiduMap () {
      const that = this
      var isHide = true
      this.$nextTick(function () {
        /** 初始化地图Start */
        var map = new BMapGL.Map('baidu-map-container', {
        //   coordsType: 3
        }) // 创建地图实例
        var point = new BMapGL.Point(112.86801868097417, 28.212339180610783) // 设置中心点坐标
        map.centerAndZoom(point, 13) // 地图初始化，同时设置地图展示级别
        map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
        /** 初始化地图End */

        /** 点击地图创建坐标事件Start */
        // 添加地图点击事件
        map.addEventListener('click', function (e) {
          var clickpt = e.latlng // 点击的坐标
          map.clearOverlays() // 移除地图上的标注
          var marker = new BMapGL.Marker(clickpt) // 创建标注
          map.addOverlay(marker) // 将标注添加到地图中
          // 逆地址解析
          that.geocAddress(clickpt)
        })
        /** 点击地图创建坐标事件End */

        /** 搜索地址Start */
        // 建立一个自动完成的对象
        this.ac = new BMapGL.Autocomplete({
          input: 'searchAddres',
          location: map
        })
        // 鼠标点击下拉列表后的事件
        this.ac.addEventListener('onconfirm', function (e) {
          map.clearOverlays() // 移除地图上的标注
          var local = new BMapGL.LocalSearch(map, {

            // 智能搜索
            onSearchComplete: function (results) {
              if (!isHide) {
                that.ac.hide()
                isHide = true
              }
              const poi = results.getPoi(0) // 获取第一个智能搜索的结果
              var searchpt = poi.point // 获取坐标
              map.centerAndZoom(searchpt, 16)
              map.addOverlay(new BMapGL.Marker(searchpt)) // 添加标注
              that.geocAddress(searchpt) // 逆地址解析
            }
          })
          // 搜索词
          var searchValue = e.item.value
          const val = searchValue.province +
                            searchValue.city +
                            searchValue.district +
                            searchValue.street +
                            searchValue.business
          local.search(
            val
          )
          that.addressInfo.searchAddresKeywords = val
          isHide = false
        })
        /** 搜索地址End */
      })
    },

    /** 逆向解析地址 point */
    geocAddress (point) {
      const that = this
      var geoc = new BMapGL.Geocoder()
      that.isflag = false
      geoc.getLocation(point, async function (geocInfo) {
        const { lat, lng } = await that.conVertor(point.lng, point.lat)
        that.isflag = true
        console.log(lat, lng, 'lat, lng ')
        // 设置基本信息
        var addressInfo = geocInfo.addressComponents
        that.addressInfo.longitude = lng
        that.addressInfo.latitude = lat
        that.addressInfo.province = addressInfo.province
        that.addressInfo.city = addressInfo.city
        that.addressInfo.district = addressInfo.district
        that.addressInfo.poiaddress = geocInfo.address

        let address = addressInfo.street + addressInfo.streetNumber
        if (geocInfo.surroundingPois.length > 0) {
          address = address + geocInfo.surroundingPois[0].title
        }
        that.addressInfo.address = address
      })
    },
    conVertor (x, y) {
      return new Promise((resolve, reject) => {
        var ggPoint = new BMapGL.Point(x, y)
        var convertor = new BMapGL.Convertor()
        var pointArr = []
        pointArr.push(ggPoint)
        convertor.translate(pointArr, 5, 3, data => {
          var result = {
            lat: data.points[0].lat,
            lng: data.points[0].lng
          }
          resolve(result)
        })
      })
    },
    /** 打开地图选择 */
    show () {
      this.openMap = true
      if (!this.ac) {
        this.initBaiduMap()
      }
    },

    /**
             * 确认选择
             */
    confirmSelect () {
      if (!this.isflag) {
        return false
      }
      if (!this.searchAddresKeywords || this.searchAddresKeywords == ' ') {
        this.addressInfo.searchAddresKeywords = ''
        const { province, district, address } = this.addressInfo
        this.addressInfo.searchAddresKeywords = province + district + address
      }
      console.log(this.addressInfo, 'this.addressInfo')
      this.isflag = false
      this.$emit('confirmMapAddress', this.addressInfo)
      this.searchAddresKeywords = ''
      this.reset()
      this.openMap = false
    },
    reset () {
      for (const key in this.addressInfo) {
        this.addressInfo[key] = ''
      }
    },

    /**
             * 取消选择
             */
    cancel () {
      this.openMap = false
      this.reset()
    }
  },
  beforeDestroy () {
    this.ac = null
  }
}

</script>

<style lang="less">
    // 防止地图自动完成的对象被遮挡
    .tangram-suggestion {
        z-index: 9999;
    }

</style>
